import React, { useState, useEffect, useContext } from 'react';
import ReactHTMLParser from 'react-html-parser';
import { Link } from '@latitude/link';
import { BUTTON_STYLE } from '@latitude/core/utils/constants';
import { Heading4 } from '@latitude/heading';
import BrandedCallout from '@latitude/callout/BrandedCallout';
import { Box } from '@/components/Box/Box';
import ImportantInformation from '@/components/ImportantInformation/ImportantInformation';
import Layout from '@/components/layout';
import MobileAppInstallPrompts from '@latitude/mobile-app-install-prompt/MobileAppInstallPrompt';
import PageData from '@/data/pages/calculators-and-tools/car-loan-repayment-calculator.json';
import StickyNavigationBranded from '@/components/StickyNavigation/StickyNavigationBranded';
import PersonalLoanCalculator from '@/components/PersonalLoanCalculator/PersonalLoanCalculator';
import PLDisclaimerContent from '@/components/PersonalLoanCalculator/PersonalLoanDisclaimerContent';
import { ConfettiFramedContainer } from '@latitude/confetti-framed-container';
import HowToApplyList from '@/components/HowToApply/HowToApplyList';
import { FeatureTiles } from '@latitude/feature-tiles';
import {
  ALIGN,
  BREAKPOINT,
  COLOR,
  DISPLAY,
  MARGIN,
  PADDING,
  POSITION,
  PL_APPLY_CTA_TRACK_ID,
  PL_SYMPLE_MINIMUM_AMOUNT,
  PL_SYMPLE_DEFAULT_PERIOD
} from '@/utils/constants';
import {
  redirectUrl,
  softQuoteHrefFunc
} from '@/components/EstimateRateWidget/utils';
import heroImage from '../images/hero/car-loan-calculator.webp';

import { PageContext } from '@/context/PageContext';
import { CONTENTFUL_COMPONENTS } from '@/utils/constants';
import HeroBanner from '@/components/lab-components/HeroBanner';
import { useSetState } from '@/utils/hooks';
import PageHeaderSection from '@/components/PageLayout/PageHeader';

// eslint-disable-next-line arrow-body-style
const PLCalculatorPage = ({ location }) => {
  // soft quote
  const [softQuoteHref, setSoftQuoteHref] = useState();
  useEffect(() => {
    sessionStorage.setItem('purpose', 'purpose'); // reset session val used in `EstimateRateWidgetSection`
    sessionStorage.setItem('loanAmount', PL_SYMPLE_MINIMUM_AMOUNT.toString());
    sessionStorage.setItem('repaymentPeriod', PL_SYMPLE_DEFAULT_PERIOD);
    setSoftQuoteHref(redirectUrl('au', true));
  }, []);

  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};
  /** - END - */

  return (
    <Layout
      location={location}
      title={PageData.title}
      metaDesc={PageData.description}
      noMetaKeywords
      canonical="/car-loan-repayment-calculator/"
    >
      <main className="navigation-spacer">
        <Box backgroundColor={COLOR.GREY6}>
          <MobileAppInstallPrompts />
          
          {state?.heroBannerData?.[0] ? (
            <HeroBanner {...state?.heroBannerData[0]} />
          ) : (
            <PageHeaderSection
              pageImage={heroImage}
              title={
                <>
                  Car Loan
                  <br />
                  Repayment Calculator
                </>
              }
              subTitle="Use our handy calculator to figure out what your Car Loan repayments might look like."
            />
          )}
          <div
            className="d-none d-lg-block"
            css="position:relative; z-index:2;"
          >
            <StickyNavigationBranded
              items={PageData.nav}
              phoneNumber={PageData.content.phoneNumber}
              ctaHref={softQuoteHrefFunc(softQuoteHref)}
              ctaText="Get Rate Estimate"
              offsetElem="[data-sticky-navigation-offset]"
              target="_self"
              trackEventData={{
                category: 'cta',
                action: 'quote-link',
                label: 'Get Rate Estimate',
                location: 'check-your-rate'
              }}
            />
          </div>
          <Box
            id={PageData.nav[0].anchor}
            backgroundColor={COLOR.BLUE_SKY}
            position={POSITION.RELATIVE}
          >
            <Box padding={`${PADDING.P48} 15px 0 15px`}>
              <Heading4 color={COLOR.BLACK} align={ALIGN.CENTER}>
                Calculate your repayments
              </Heading4>
            </Box>
            <Box isResponsive margin={`${MARGIN.M0} ${MARGIN.MAUTO}`}>
              <PersonalLoanCalculator
                loans1
                applyCTATrackId={PL_APPLY_CTA_TRACK_ID}
                disclaimerContent={<PLDisclaimerContent />}
              />
            </Box>
          </Box>
          <Box id={PageData.nav[1].anchor}>
            <BrandedCallout
              moreVerticalPadding
              hasConfettiBackground
              confettiBackground={PageData.content.callOut.bgImage}
              confettiBackgroundColor={COLOR.WHITE}
              confettiBackgroundRepeat="repeat"
              confettiBackgroundPos="center 35%"
              heading={PageData.content.callOut.title}
              line1={ReactHTMLParser(PageData.content.callOut.content)}
              cta={
                <Box>
                  <Link
                    button={BUTTON_STYLE.SECONDARY}
                    href="/car-loan/"
                    trackId="callout-find-out-more"
                    className="w-auto brandedCallout__cta mt-lg-4"
                    style={{
                      paddingLeft: '24px',
                      paddingRight: '24px'
                    }}
                  >
                    {PageData.content.callOut.ctaLabel}
                  </Link>
                </Box>
              }
            />
          </Box>
          <ConfettiFramedContainer
            id={PageData.nav[2].anchor}
            heading={PageData.content.howToApply.title}
            minHeight="240px"
            innerContainerMaxWidth="730px"
            hasPlainBgColor={COLOR.BLUE_BABY}
            framedContainerTheme={{
              frameColor: COLOR.BLUE_SKY,
              contentBorderColor: DISPLAY.NONE
            }}
          >
            <Box padding="24px 24px 24px 40px">
              <HowToApplyList className="p-4" noHeading variant="sym" />
            </Box>
          </ConfettiFramedContainer>
          <FeatureTiles
            id={PageData.nav[3].anchor}
            heading={PageData.content.calcTools.title}
            tiles={PageData.content.calcTools.tiles}
          />
          <ImportantInformation
            data={require('@/data/json/disclaimer/personal-loan1.json')}
            sectionOneColummClasses="col-10 offset-1 col-lg-5 offset-lg-1"
            sectionTwoColummClasses="col-10 offset-1 col-lg-5 offset-lg-0"
            additionalPaymentsDisclaimerOne
          />
        </Box>
      </main>
    </Layout>
  );
};

export default PLCalculatorPage;
